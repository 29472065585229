<template>
  <v-container fluid class="px-0">
    <v-row>
      <v-col cols="6" md="4">
        <v-card>
          <v-container class="pa-0">
            <v-card-text class="text-body-2">
              <p class="font-weight-bold">Top products</p>
              <div class="d-flex mb-1" v-for="product in products" :key="product[0].id">
                {{ product[0].code }}
                <v-spacer></v-spacer>
                <span class="grey--text">{{ product[1] }}</span>
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="6" md="4">
        <v-card>
          <v-container class="pa-0">
            <v-card-text class="text-body-2">
              <p class="font-weight-bold">Total Sales</p>
              <div class="d-flex mb-1" v-for="(value, index) in chartData.datasets[0].data.slice(-3)" :key="index">
                <v-col class="pa-0" cols="7">
                  {{ chartData.labels[chartData.labels.length - 3 + index].toUpperCase() }}
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="5" md="2" class="pa-0">
                  <span class="grey--text">${{ value }}</span>
                </v-col>
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8">
        <v-card>
          <v-container class="pa-0">
            <v-card-text class="text-body-2">
              <p class="font-weight-bold">Monthly Sales (BND$)</p>
              <Bar :chart-data="graphData" :chart-options="chartOptions" :styles="chartStyles"/>
              <v-row class="mt-2" v-if="$vuetify.breakpoint.xs">
                <v-col cols="6" class="pa-0">
                  <v-btn
                    icon
                    @click="page = 1"
                    :disabled="page == 1"
                  >
                    <v-icon color="primary">mdi-chevron-left</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="6" class="pa-0" align="end">
                  <v-btn
                    icon
                    @click="page = 2"
                    :disabled="page == 2"
                  >
                    <v-icon color="primary">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiService from '@/services/api.service.js'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import { Bar } from 'vue-chartjs'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip)

export default {
  components: { Bar },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [{
          label: 'Monthly Sales',
          backgroundColor: '#A78FB3',
          data: [] 
        }],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: ((context) => {
                return `${context.dataset.label}: BND$${context.parsed.y}`
              })
            }
          }
        }
      },
      chartStyles: { height: '430px' },
      products: [],
      page: 2
    }
  },
  methods: {
    async fetchSales() {
      try {
        const res = await apiService.get('api/v1/admin/dashboard')
        const response = res.data
        this.products = response.data.top_products
        this.chartData.labels = response.data.sales_data.labels
        this.chartData.datasets[0].data = response.data.sales_data.values.map(value => (parseFloat(value)))
      }
      catch(error) {
        this.$store.dispatch('showSnackbar', 
          { 
            content: error.response.data.message || 'There was an issue retrieving the sales at this moment. Please try again at a later time', 
            status: 'error' 
          })
      }
    }
  },
  computed: {
    graphData() {
      if (this.$vuetify.breakpoint.xs) {
        switch (this.page) {
          case 1:
            return {
              labels: this.chartData.labels.slice(0, 4),
              datasets: [{
                label: 'Monthly Sales',
                backgroundColor: '#A78FB3',
                data: this.chartData.datasets[0].data.slice(0, 4)
              }]
            }
          case 2:
            return {
              labels: this.chartData.labels.slice(-3),
              datasets: [{
                label: 'Monthly Sales',
                backgroundColor: '#A78FB3',
                data: this.chartData.datasets[0].data.slice(-3)
              }]
            }
        }
      } 
      return this.chartData
    }
  },
  created () {
    this.fetchSales()
  },
}
</script>